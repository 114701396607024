import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
} from 'react-router-dom';

const {
  REACT_APP_AUTH0_PROVIDER_DOMAIN = '',
  REACT_APP_AUTH0_PROVIDER_CLIENT_ID = '',
  REACT_APP_AUTH0_PROVIDER_AUDIENCE = ''
} = process.env;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
       domain={REACT_APP_AUTH0_PROVIDER_DOMAIN}
       clientId={REACT_APP_AUTH0_PROVIDER_CLIENT_ID}
       redirectUri={window.location.origin}
       audience={REACT_APP_AUTH0_PROVIDER_AUDIENCE}
       scope="scopes read:dev_portal"
     >
    <Router>
      <App />
    </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
