import { useRef } from 'react';
import 'rapidoc';

const isDev = process.env.NODE_ENV === 'development';

declare global {
  namespace JSX {
      interface IntrinsicElements {
          'rapi-doc': any;
      }
  }
}

export const ApiDoc = (props: any) => {
  const rapiDocRef = useRef(null);
  const { specUrl } = props;

  return (
    <rapi-doc
      ref={rapiDocRef}
      style={{ height: "80vh", width: "100%" }}
      spec-url={specUrl}
      render-style="read"
      theme="light"
      font-size="largest"
      primary-color="#e5573b"
      show-header="false"
      nav-bg-color="#0d264d"
      nav-text-color="#eeedef"
      allow-authentication="true"
      // add localhost for development
      server-url={isDev ? 'http://localhost:3003/api' : null}
      default-api-server={isDev ? 'http://localhost:3003/api' : null }
      // api-key-location="header"
      // api-key-name="Authorization"
      // api-key-value="Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlVWVUdFTFFGMWNtTDhKWnZwWjZmSiJ9.eyJpc3MiOiJodHRwczovL2N5bW9uei5hdS5hdXRoMC5jb20vIiwic3ViIjoiTUdTQ0ZLYnJJTVZyOXR1bEFmYjNtVU9tSUVXSFBiYUdAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vY3ltb256LmF1LmF1dGgwLmNvbS9hcGkvdjIvIiwiaWF0IjoxNjI2MDYzODA3LCJleHAiOjE2MjYxNTAyMDcsImF6cCI6Ik1HU0NGS2JySU1Wcjl0dWxBZmIzbVVPbUlFV0hQYmFHIiwic2NvcGUiOiJyZWFkOnVzZXJzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIn0.nV1wxqg5eBAGYyIxB-yc8GNpSjqtApqOdM81Onfp4XdyJiGyxNBI-4PJmE8gVb6hUinrTG4oVyxzSy_3BG_jbQIx7Ff5KXR6rqfhY71ML8IP50fIZalYiH828qr77uRLvEyjEREK02I-jslBBPZidQqzfpz279aByfGQHxx6wLed-sslUoDNv3ZWTvtDn7iv9WEJpLDty1Cshzd1YrNqr8ROWjQ2phGV3feflbNg4mJK9aBEPg8lK3XExupkF8TTjd58eG-9f_Bt51rWsHYqL3AFgGOi3R1RMY9G-YlFGMYzIMgTHygZiZLc_oB6RU7JSgpCh2rwJrROhgaWmteGdw"
    />
  );

}
